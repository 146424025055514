body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
background-color: black;
}

//////////// NAV //////////////////////////////////
//////////// NAV //////////////////////////////////
.Nav{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1% 1%;
  background-color: white;
  .Navs{
    color:black;
    font-size: x-large;
    font-family: 'Anton', sans-serif;
      
    
  }  
}

//////////// HEADER //////////////////////////////////
//////////// HEADER //////////////////////////////////
.header{
  background-image: url('./assets/ap.png');
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 1%;
  padding-bottom: 40%;
  text-align: center;
  a{
    font-size: x-large;
    color: white;
    text-decoration: none;
    padding-top: 10%;
  }
}
//////////// NAV2 //////////////////////////////////
//////////// NAV2 //////////////////////////////////
.nav2{
  text-align: center;
  padding-bottom: 1%;
  background-color: rgba(169, 169, 169, 0.489);
  a{
  color: white;
  font-size: x-large;
  text-decoration: none;


}
}
//////////// 50/50 //////////////////////////////////
//////////// 50/50 //////////////////////////////////
.sidebyside{
  text-align: center;
  display: flex;
  h1{
    font-size: 4rem;
    color: grey;
  }
  .side{
    width: 50%;
  }
  a{
    font-size: 2rem;
    color: white;
    text-decoration: none;
    width: 75%;
  }
  .sbs{
    width: 100%;
    height: 100%;
  }
}

///////////WHY////////////
///////////WHY////////////
.whyus{
  color: grey;
  h1{
  font-size: 3rem;
}
  p{
    width: 75%;
    padding-left: 12%;
    font-size: xx-large;
  }
  .circles{
    height: 25.5rem;
    width: 25.5rem;
    border-radius: 50%;
    padding-left: 2%;
  }
}

//////////// HEADER //////////////////////////////////
//////////// HEADER //////////////////////////////////
.header{
  h1{
    color: white;
    font-size: 4rem;
    text-align: center;
    padding-top: 0;
  }
  img{
    width: 40%;
    padding-left: 50%;
  }
}

//////////// VIDEOS //////////////////////////////////
//////////// VIDEOS //////////////////////////////////
.videos{
  background-image: url('./assets/bg.gif');
  background-repeat: no-repeat;
  text-align: center;
  height: 100vh;
  h1{
    color: white;
    font-size: 4rem;
  }
  a{
    font-size: 2rem;
    color: white;
    text-decoration: none;
  }
}

//////////// MERCH //////////////////////////////////
//////////// MERCH //////////////////////////////////
.merch{
  text-align: center;
  height: 100vh;
  h1{
    color: white;
    font-size: 4rem;
  }
  a{
    font-size: 2rem;
    color: white;
    text-decoration: none;
  }
  
  img{
    width: 25%;
    padding-left: 5%;
  }
}
//////////// MUSIC //////////////////////////////////
//////////// MUSIC //////////////////////////////////
.music{
  text-align: center;
  h1{
    color: white;
    font-size: 4rem;
  }
  a{
    font-size: 2rem;
    color: white;
    text-decoration: none;
  }
}


//////////MEDIA //////////////////////////////////
//////////MEDIA //////////////////////////////////
//////////MEDIA //////////////////////////////////
//////////MEDIA //////////////////////////////////
//////////MEDIA //////////////////////////////////
@media screen and (max-width: 768px) {
  //////////// NAV //////////////////////////////////
  //////////// NAV //////////////////////////////////
  .Nav{
    display: block;
    justify-content: space-between;
    align-items: center;
    padding: 20px 80px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    .Navs{
      color:black;
      font-size: large;
      font-family: 'Anton', sans-serif;
      
      
    }  
  }
  //////////// NAV2 //////////////////////////////////
//////////// NAV2 //////////////////////////////////
.nav2{
  text-align: center;
  padding-bottom: 1%;
  background-color: rgba(169, 169, 169, 0.489);
a{
  color: white;
  font-size: large;
  text-decoration: none;


  }
}

  //////////// HEADER //////////////////////////////////
  //////////// HEADER //////////////////////////////////
  .header{
    background-image: url('./assets/ap.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 1%;
    padding-bottom: 55%;
    text-align: center;
    a{
      font-size:x-large;
      color: red;
      text-shadow: 3px 3px 3px white;
      text-decoration: none;
    }
  }

  ///////////WHY////////////
///////////WHY////////////
.whyus{
  text-align: center;
  color: white;
  h1{
    color: grey;
  font-size: 2rem;
}
  p{
    width: 75%;
    padding-left: 12%;
    font-size: x-large;
  }
  .circles{
    height: 18.5rem;
    width: 18.5rem;
    border-radius: 50%;
    padding-left: 0%;
    padding-bottom: 2%;
  }
}
/*
//////////// 50/50 //////////////////////////////////
//////////// 50/50 //////////////////////////////////
.sidebyside{
  text-align: center;
  display: block;
  h1{
    font-size: 3rem;
    color: grey;
  }
  .side{
    width: 100%;
  }
  a{
    font-size: x-large;
    color: white;
    text-decoration: none;
    width: 75%;
  }
  .sbs{
    width: 100%;
    height: 100%;
  }
}

//////////// VIDEOS //////////////////////////////////
//////////// VIDEOS //////////////////////////////////
.videos{
  background-image: url('./assets/bg.gif');
  background-repeat: no-repeat;
  text-align: center;
  height: 100vh;
  h1{
    color: white;
    font-size: 4rem;
  }
  a{
    font-size: 2rem;
    color: white;
    text-decoration: none;
  }
}
*/

//////////// MERCH //////////////////////////////////
//////////// MERCH //////////////////////////////////
.merch{
  background-image: none;
  text-align: center;
  width: 100%;
  padding-bottom: 55%;
  h1{
    color: grey;
    background-color: white;
    font-size: 3rem;
  }
  a{
    font-size: 2rem;
    color: white;
    text-decoration: none;
  }
  .shirts{
    width: 100%;
    padding-bottom: 2%;
  }
}

//////////// MUSIC //////////////////////////////////
//////////// MUSIC //////////////////////////////////
.music{
  text-align: center;

  h1{
    color: white;
    font-size: 2rem;
  }
  a{
    font-size: 2rem;
    color: white;
    text-decoration: none;
  }
}


}